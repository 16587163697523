export const tariffTypeMaintenanceRequiredFieldList: string[] = [
    'tariffType', 
];

export const TariffTypeMaintenanceConstant = {    
    CATEGORY: "Code",
    TITLE: "Tariff Type",
    TARIFF_TYPE: "Tariff Type",
    TARIFF_TYPE_DESC: 'Tariff Type Desc',
    ACTIVE_IND: 'Active Ind.',
}
