import { TariffTypeMaintenanceConstant } from "./TariffTypeMaintenanceConstant";

const SCREEN_CONSTANT = TariffTypeMaintenanceConstant;
export const INITIAL_TARIFF_TYPE_COL_DEF: any[] = [
        {
            headerName: SCREEN_CONSTANT.TARIFF_TYPE,
            field: "tariffType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 140,
            //pinned: "left",
            //checkboxSelection: true,
            //headerCheckboxSelection: true,
            
        },
        {
            headerName: SCREEN_CONSTANT.TARIFF_TYPE_DESC,
            field: "tariffTypeDesc",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
        },
        {
            headerName: SCREEN_CONSTANT.ACTIVE_IND,
            field: "activeInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 130,
        },
]
