import { SelectionChangedEvent } from "ag-grid-community";
import { TariffTypeEntity } from "domain/entity/TariffType/TariffTypeEntity";
import { INITIAL_TARIFF_TYPE_COL_DEF } from "presentation/constant/TariffType/TariffTypeMaintenanceColumnDefinition";
import { TariffTypeMaintenanceConstant } from "presentation/constant/TariffType/TariffTypeMaintenanceConstant";
import { useTariffTypeMaintenanceVM } from "presentation/hook/TariffType/useTariffTypeMaintenanceVM";
import { useTariffTypeMaintenanceTracked } from "presentation/store/TariffType/TariffTypeMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


const TariffTypeTablePanel:React.FC = () => {
    const [tariffTypeState] = useTariffTypeMaintenanceTracked();
    const tariffTypeVM = useTariffTypeMaintenanceVM();
    const gridRef: any = useRef(null);
    const {tableData} = tariffTypeState;

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {     
        const selectedRows = e.api.getSelectedRows();

        tariffTypeVM.updateSelectedRows(tableData,selectedRows);
    }, [tariffTypeVM, tableData])

    const handleAddClick = useCallback(() => {
        tariffTypeVM.onAdd();
    }, [tariffTypeVM])

    const handleRowDoubleClick = useCallback((entity: TariffTypeEntity) => {
          tariffTypeVM.onEdit(entity);
    }, [tariffTypeVM])

    const memoTable = useMemo(() => {
        return (
        <HPHTable
            id='tariff-type-table'
            headerLabel={TariffTypeMaintenanceConstant.TITLE}
            isNewColumnSetting={true}
            columns={INITIAL_TARIFF_TYPE_COL_DEF}
            data={tableData??[]}
            showPaginator={false}
            editable={false}
            showAddIcon={true}
            onAddClick={handleAddClick}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            isRowHighligted={true}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any, entity: TariffTypeEntity) => handleRowDoubleClick(entity)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
            />
        );
    },[tableData, handleAddClick, handleSelectionChange, handleRowDoubleClick])

    return <><TableWrapper>
            {memoTable}
        </TableWrapper>
        </>;
}

export default memo(TariffTypeTablePanel);
